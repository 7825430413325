/* autoprefixer grid: on */

/* Define Custom Media queries */

/* Define global CSS variables inside :root */

:root {
  /*--content-max-width: calc((100vw / 14) * 12);*/
  --content-max-width-float: 0.857142;
  --content-max-width: calc(100% * var(--content-max-width-float));

  /*--grid-column: calc(100vw / 14);*/

  --grid-column: 7.1428vw;
  --grid-column-half: 3.5714vw;
  --grid-columns-2: 14.2857vw;
  --grid-columns-3: 21.4285vw;
  --grid-columns-4: 28.5714vw;
  --grid-columns-5: 35.7142vw;
  --grid-columns-6: 42.8571vw;
  --grid-columns-7: 50vw;
  --grid-columns-8: 57.1428vw;
  --grid-columns-9: 64.2857vw;
  --grid-columns-10: 71.4285vw;
  --grid-columns-11: 78.5714vw;
  --grid-columns-12: 85.7142vw;
  --grid-columns-13: 92.8571vw;

  --grid-column-pct: 7.1428%;
  --grid-column-half-pct: 3.5714%;
  --grid-columns-2-pct: 14.2857%;
  --grid-columns-3-pct: 21.4285%;
  --grid-columns-4-pct: 28.5714%;
  --grid-columns-5-pct: 35.7142%;
  --grid-columns-6-pct: 42.8571%;
  --grid-columns-7-pct: 50%;
  --grid-columns-8-pct: 57.1428%;
  --grid-columns-9-pct: 64.2857%;
  --grid-columns-10-pct: 71.4285%;
  --grid-columns-11-pct: 78.5714%;
  --grid-columns-12-pct: 85.7142%;
  --grid-columns-13-pct: 92.8571%;

  /*--grid-row: calc(100vh / 14);*/
  --grid-row: 7.1428vh;
  --grid-row-half: 3.5714vh;
  --grid-rows-1-half: 10.7142vh;
  --grid-rows-2: 14.2857vh;
  --grid-rows-3: 21.4285vh;
  --grid-rows-4: 28.5714vh;
  --grid-rows-5: 35.7142vh;
  --grid-rows-6: 42.8571vh;
  --grid-rows-7: 50vh;
  --grid-rows-8: 57.1428vh;
  --grid-rows-9: 64.2857vh;
  --grid-rows-10: 71.4285vh;
  --grid-rows-11: 78.5714vh;
  --grid-rows-12: 85.7142vh;
  --grid-rows-13: 92.8571vh;

  --grid-gutter: 0rem;

  --header-height: 10vh;

  /* Colors */
  --color-black: #111111;
  --color-minimum-contrast-to-black: #9E9E9E;

  --color-blackish: #1a1a1a;

  --color-black-light: #272727;
  --color-minimum-contrast-to-black-light: #B2B2B2;

  --color-gray-dark: #333333;
  --color-gray: #707070;
  --color-gray-facet: #8f8f8f;
  --color-gray-light: #cccccc;
  --color-white: #f5f5f5;
  --color-accent: #f7931e;

  /* Utility colors */
  --color-button-border: var(--color-gray-light);

  --anitime-filters: 0.5s;
  --anitime-datalist: 0.2s;
  --opacity-borders: 0.2;
  --cubic-ease-in-out: cubic-bezier(0.5, 0, 0.5, 1);
  --cubic-ease-out: cubic-bezier(0.5, 1, 0.5, 1);

  /* Set the default font to use on the site */
  --font-hill: "Hill", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  --font-size-base: 19px;
  --font-lineheight-base: 1.33em;

  --font-size-tiny: 0.6rem;
  --font-size-meta: 0.78947368rem;
  --font-size-small: 0.84210526rem;
  --font-size-body: 1rem;
  --font-size-header: calc(17 / 19 * 1rem);
  --font-size-header-small-mobile: 1.15rem;
  --font-size-header-medium-mobile: 1.44rem;
  --font-size-header-large-mobile: 2rem;
  --font-size-header-huge-mobile: 3rem;
  --font-size-header-small: 1.3rem;
  --font-size-header-medium: 2rem;
  --font-size-header-large: 2.525rem;
  --font-size-header-huge: 4rem;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-demibold: 600;
  --font-weight-bold: 700;

  --font-lineheight-inputs: 1.5em;
  --font-lineheight-body: 1.33em;
  --font-lineheight-richtext: 1.75em;
  --font-lineheight-header: 1em;
  --font-lineheight-headers: 1.1em;

  --font-margin-bottom-headers: 0.5em;

  --z-index-filter-by: 1;
  --z-index-filters-desktop: 101;
  --z-index-navigation-bar: 102;
  --z-index-filters-mobile: 103;
  --z-index-menu-mobile: 104;
  --z-index-modal: 105;
  --z-index-search-filters: 106;
  --z-index-cookies: 107;

  --intro-margin-bottom: 1.5rem;
  --intro-sm-margin-bottom: 3rem;

  --facets-sm-width: 45vw;
  --facets-md-width: 330px;
  --facet-sm-width: 45vw;
  --facet-md-width: 330px;

  --masonry-gap: 0.75rem;
}

@font-face {
  font-family:'Hill';
  src:	url('/static/fonts/collection/hill_light.eot');
  src:	url('/static/fonts/collection/hill_light.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/collection/hill_light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family:'Hill';
  src:	url('/static/fonts/collection/hill_regular.eot');
  src:	url('/static/fonts/collection/hill_regular.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/collection/hill_regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family:'Hill';
  src:	url('/static/fonts/collection/hill_demibold.eot');
  src:	url('/static/fonts/collection/hill_demibold.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/collection/hill_demibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family:'Hill';
  src:	url('/static/fonts/collection/hill_bold.eot');
  src:	url('/static/fonts/collection/hill_bold.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/collection/hill_bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* ADD XLARGE */

@media only screen and (min-width: 90em) {
  .container {
    width: 91rem;
  }

  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-offset-0,
  .col-xl-offset-1,
  .col-xl-offset-2,
  .col-xl-offset-3,
  .col-xl-offset-4,
  .col-xl-offset-5,
  .col-xl-offset-6,
  .col-xl-offset-7,
  .col-xl-offset-8,
  .col-xl-offset-9,
  .col-xl-offset-10,
  .col-xl-offset-11,
  .col-xl-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-xl {
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xl-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-xl-offset-0 {
    margin-left: 0;
  }

  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xl-offset-3 {
    margin-left: 25%;
  }

  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xl-offset-6 {
    margin-left: 50%;
  }

  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xl-offset-9 {
    margin-left: 75%;
  }

  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  [dir="ltr"] .start-xl {
    text-align: left;
  }

  [dir="rtl"] .start-xl {
    text-align: right;
  }

  .start-xl {
    -webkit-box-pack: start;
    justify-content: flex-start;
  }

  .center-xl {
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  [dir="ltr"] .end-xl {
    text-align: right;
  }

  [dir="rtl"] .end-xl {
    text-align: left;
  }

  .end-xl {
    -webkit-box-pack: end;
    justify-content: flex-end;
  }

  .top-xl {
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-xl {
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-xl {
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-xl {
    justify-content: space-around;
  }

  .between-xl {
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-xl {
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-xl {
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

/* flexboxgrid overrides */

.container-fluid {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  height: 100%;
}

.container {
  width: calc(100% * 0.857142);
  width: var(--content-max-width);
  padding-left: 0rem;
  padding-left: var(--grid-gutter);
  padding-right: 0rem;
  padding-right: var(--grid-gutter);
}

.flex {
  display: -webkit-box;
  display: flex;
}

.flex-column {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: column;
}

/* WITH GUTTER */

.row {
  margin-right: calc(-1 * 0rem);
  margin-right: calc(-1 * var(--grid-gutter));
  margin-left: calc(-1 * 0rem);
  margin-left: calc(-1 * var(--grid-gutter));
}

[class*="col-"] {
  padding-right: 0rem;
  padding-right: var(--grid-gutter);
  padding-left: 0rem;
  padding-left: var(--grid-gutter);

  -webkit-transition: margin-left 0.5s cubic-bezier(0.5, 0, 0.5, 1),
    -webkit-flex-basis 0.5s cubic-bezier(0.5, 0, 0.5, 1);

  transition: margin-left 0.5s cubic-bezier(0.5, 0, 0.5, 1),
    -webkit-flex-basis 0.5s cubic-bezier(0.5, 0, 0.5, 1);

  transition: margin-left 0.5s cubic-bezier(0.5, 0, 0.5, 1),
    flex-basis 0.5s cubic-bezier(0.5, 0, 0.5, 1);

  transition: margin-left 0.5s cubic-bezier(0.5, 0, 0.5, 1),
    flex-basis 0.5s cubic-bezier(0.5, 0, 0.5, 1),
    -webkit-flex-basis 0.5s cubic-bezier(0.5, 0, 0.5, 1);

  -webkit-transition: margin-left var(--anitime-filters) var(--cubic-ease-in-out),
    -webkit-flex-basis var(--anitime-filters) var(--cubic-ease-in-out);

  transition: margin-left var(--anitime-filters) var(--cubic-ease-in-out),
    -webkit-flex-basis var(--anitime-filters) var(--cubic-ease-in-out);

  transition: margin-left var(--anitime-filters) var(--cubic-ease-in-out),
    flex-basis var(--anitime-filters) var(--cubic-ease-in-out);

  transition: margin-left var(--anitime-filters) var(--cubic-ease-in-out),
    flex-basis var(--anitime-filters) var(--cubic-ease-in-out),
    -webkit-flex-basis var(--anitime-filters) var(--cubic-ease-in-out);
}

html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  touch-action: manipulation; /* Disables double-tap to zoom */
  background-color: #111111;
  background-color: var(--color-black);
  color: #f5f5f5;
  color: var(--color-white);
}

*, *:before, *:after {
  box-sizing: border-box;
}

div[id="root"] {
  background-color: inherit;
}

div[id="modal"] {
  background-color: inherit;
}

div[id="modal"] > * {
    background-color: inherit;
  }

section[role="main"] {
  background-color: inherit;
}

img {
  display: block;
  border: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  font-size: 0.6rem;
  font-size: var(--font-size-tiny);
  font-weight: 300;
  font-weight: var(--font-weight-light);
  line-height: 1.1em;
  line-height: var(--font-lineheight-headers);
}

caption,
figcaption {
  display: block;
  text-align: left;
}

#modal {
  z-index: 105;
  z-index: var(--z-index-modal);
  position: relative;
}

html,
body {
  font-family: "Hill", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: var(--font-hill);
  font-size: 19px;
  font-size: var(--font-size-base);
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  line-height: 1.33em;
  line-height: var(--font-lineheight-base);
}

@media (min-width: 1440px) {

html,
body {
    font-size: calc(0.15vw + 0.15vh + 19px - 3px);
    font-size: calc(0.15vw + 0.15vh + var(--font-size-base) - 3px)
}
  }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Headers */

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}

.h1[class*="secondary"],
  .h1 span.secondary,
  .h2[class*="secondary"],
  .h2 span.secondary,
  .h3[class*="secondary"],
  .h3 span.secondary,
  .h4[class*="secondary"],
  .h4 span.secondary,
  .h5[class*="secondary"],
  .h5 span.secondary,
  .h6[class*="secondary"],
  .h6 span.secondary,
  h1[class*="secondary"],
  h1 span.secondary,
  h2[class*="secondary"],
  h2 span.secondary,
  h3[class*="secondary"],
  h3 span.secondary,
  h4[class*="secondary"],
  h4 span.secondary,
  h5[class*="secondary"],
  h5 span.secondary,
  h6[class*="secondary"],
  h6 span.secondary {
    font-weight: 300;
    font-weight: var(--font-weight-light);
  }

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  line-height: 1.1em;
  line-height: var(--font-lineheight-headers);
  margin-bottom: 0.5em;
  margin-bottom: var(--font-margin-bottom-headers);
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-bottom: 0;
}

.h1,
h1 {
  font-size: 2rem;
  font-size: var(--font-size-header-large-mobile);
}

@media (min-width: 1200px) {

.h1,
h1 {
    font-size: 2.525rem;
    font-size: var(--font-size-header-large)
}
  }

.h2,
h2 {
  font-size: 1.44rem;
  font-size: var(--font-size-header-medium-mobile);
}

@media (min-width: 1200px) {

.h2,
h2 {
    font-size: 2rem;
    font-size: var(--font-size-header-medium)
}
  }

.h3,
h3 {
  font-size: 1.15rem;
  font-size: var(--font-size-header-small-mobile);
}

@media (min-width: 1200px) {

.h3,
h3 {
    font-size: 1.3rem;
    font-size: var(--font-size-header-small)
}
  }

.h4,
h4 {
  font-size: 1rem;
}

.h5,
h5 {
  font-size: 0.78947368rem;
  font-size: var(--font-size-meta);
}

.h1 + .h1,
.h2 + .h2,
.h3 + .h3,
.h4 + .h4,
h1 + h1,
h2 + h2,
h3 + h3,
h4 + h4 {
  margin-top: calc(-1 * 0.5em);
  margin-top: calc(-1 * var(--font-margin-bottom-headers));
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 2em;
}

b,
strong {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}

small {
  font-size: 0.78947368rem;
  font-size: var(--font-size-meta);
  line-height: 1.33em;
  line-height: var(--font-lineheight-body);
}

small * {
    font-size: 0.78947368rem;
    font-size: var(--font-size-meta);
    line-height: 1.33em;
    line-height: var(--font-lineheight-body);
  }

a {
  color: currentColor;
  text-underline-offset: 2px;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.5px;
}

svg {
  fill: currentColor;
  stroke: currentColor;
  font-family: "Hill", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: var(--font-hill);
  font-size: 1rem;
  font-size: var(--font-size-body);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  line-height: 1.33em;
  line-height: var(--font-lineheight-body);
}

ul,
ol {
  margin-top: 0;
  padding-left: 1em;
}

blockquote {
  position: relative;
  display: block;
  margin: 2rem 0;
  font-size: 1.3rem;
  font-size: var(--font-size-header-small);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  line-height: 1;
}

blockquote:before {
    position: absolute;
    content: "“";
    top: 2px;
    left: calc(-1 * (7.1428vw / 3));
    left: calc(-1 * (var(--grid-column) / 3));
    font-size: 2rem;
    font-size: var(--font-size-header-medium);
    line-height: 2rem;
    line-height: var(--font-size-header-medium);
  }

@media (min-width: 1024px) {

blockquote:before {
      left: calc(-1 * (7.1428vw / 2));
      left: calc(-1 * (var(--grid-column) / 2))
  }
    }

blockquote > * {
    display: block;
    margin: 0;
    font-size: 1.3rem;
    font-size: var(--font-size-header-small);
    font-weight: 700;
    font-weight: var(--font-weight-bold);
    line-height: 1.33em;
    line-height: var(--font-lineheight-body);
  }

blockquote cite {
    font-size: 0.78947368rem;
    font-size: var(--font-size-meta);
    font-weight: 400;
    font-weight: var(--font-weight-regular);
    font-style: normal;
  }

address {
  font-style: normal;
}

hr {
  border: none;
  background-color: transparent;
  color: transparent;
  height: 0;
  margin: 2rem 0;
}

::-moz-selection {
  background: #f5f5f5;
  background: var(--color-white);
  color: #111111;
  color: var(--color-black);
}

::selection {
  background: #f5f5f5;
  background: var(--color-white);
  color: #111111;
  color: var(--color-black);
}

@media (hover: hover) {
    input[type="submit"]:hover, input[type="submit"]:not([class]):hover {
      box-shadow: inset 0 0 0 2px currentColor !important;
    }
  }

input[type="submit"],
button[type="submit"],
button,
.button {
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  font-size: 0.78947368rem;
  font-size: var(--font-size-meta);
  font-weight: 400 !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: 1.1em;
  line-height: var(--font-lineheight-headers);
  background: transparent;
  border: none;
  border-radius: 0;
  overflow: hidden;
  cursor: pointer;
  color: currentColor;
  outline: none;
}

input[type="submit"][disabled], button[type="submit"][disabled], button[disabled], .button[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

input[type="submit"] svg + span, button[type="submit"] svg + span, button svg + span, .button svg + span {
    margin-left: 0.5rem;
  }

input[type="submit"] span + svg, button[type="submit"] span + svg, button span + svg, .button span + svg {
    margin-left: 0.5rem;
  }

input[type="submit"] > *, button[type="submit"] > *, button > *, .button > * {
    pointer-events: none;
  }

input[type="submit"].small, button[type="submit"].small, button.small, .button.small {
    height: calc(1rem + 5px);
    padding-top: 1px;
  }

input[type="submit"].primary, button[type="submit"].primary, button.primary, .button.primary {
    padding: 1px calc((1rem) - 2px) 0;
    box-shadow: inset 0 0 0 2px #cccccc;
    box-shadow: inset 0 0 0 2px var(--color-button-border);
    -webkit-transition: box-shadow 0s;
    transition: box-shadow 0s;
    height: 50px;
  }

input[type="submit"].primary.active, button[type="submit"].primary.active, button.primary.active, .button.primary.active {
      box-shadow: none;
      background-color: #f5f5f5;
      background-color: var(--color-white);
      color: #111111;
      color: var(--color-black);
      border: 2px solid #f5f5f5;
      border: 2px solid var(--color-white);
    }

input[type="submit"].primary.active:before,
      input[type="submit"].primary.active:after,
      button[type="submit"].primary.active:before,
      button[type="submit"].primary.active:after,
      button.primary.active:before,
      button.primary.active:after,
      .button.primary.active:before,
      .button.primary.active:after {
        content: none;
      }

input[type="submit"].primary:before,
    input[type="submit"].primary:after,
    button[type="submit"].primary:before,
    button[type="submit"].primary:after,
    button.primary:before,
    button.primary:after,
    .button.primary:before,
    .button.primary:after {
      position: absolute;
      box-sizing: inherit;
      content: "";
      border: 2px solid transparent;
      width: 0;
      height: 0;
    }

/*left, top (expands up, then right)*/

input[type="submit"].primary:before, button[type="submit"].primary:before, button.primary:before, .button.primary:before {
      bottom: 0;
      left: 0;
    }

/*right, bottom (expands down, then left)*/

input[type="submit"].primary:after, button[type="submit"].primary:after, button.primary:after, .button.primary:after {
      top: 0;
      right: 0;
    }

@media (hover: hover) {
      input[type="submit"].primary:hover, button[type="submit"].primary:hover, button.primary:hover, .button.primary:hover {
        box-shadow: inset 0 0 0 2px transparent;
        -webkit-transition: box-shadow 0.3s;
        transition: box-shadow 0.3s;
      }

        input[type="submit"].primary:hover:before,
        input[type="submit"].primary:hover:after,
        button[type="submit"].primary:hover:before,
        button[type="submit"].primary:hover:after,
        button.primary:hover:before,
        button.primary:hover:after,
        .button.primary:hover:before,
        .button.primary:hover:after {
          width: 100%;
          height: 100%;
        }

        input[type="submit"].primary:hover:before, button[type="submit"].primary:hover:before, button.primary:hover:before, .button.primary:hover:before {
          border-left-color: #cccccc;
          border-left-color: var(--color-button-border);
          border-top-color: #cccccc;
          border-top-color: var(--color-button-border);
          -webkit-transition: border-left-color 0s, border-top-color 0s 0.15s,
            height 0.15s ease-out, width 0.15s ease-out 0.15s;
          transition: border-left-color 0s, border-top-color 0s 0.15s,
            height 0.15s ease-out, width 0.15s ease-out 0.15s;
        }

        input[type="submit"].primary:hover:after, button[type="submit"].primary:hover:after, button.primary:hover:after, .button.primary:hover:after {
          border-right-color: #cccccc;
          border-right-color: var(--color-button-border);
          border-bottom-color: #cccccc;
          border-bottom-color: var(--color-button-border);
          -webkit-transition: border-right-color 0s 0.3s, border-bottom-color 0s 0.45s,
            height 0.15s ease-out 0.3s, width 0.15s ease-out 0.45s;
          transition: border-right-color 0s 0.3s, border-bottom-color 0s 0.45s,
            height 0.15s ease-out 0.3s, width 0.15s ease-out 0.45s;
        }
    }

input[type="submit"].secondary, button[type="submit"].secondary, button.secondary, .button.secondary {
    padding: 4px 0 0 0;
    border: none;
  }

input[type="submit"].secondary:before,
    input[type="submit"].secondary:after,
    button[type="submit"].secondary:before,
    button[type="submit"].secondary:after,
    button.secondary:before,
    button.secondary:after,
    .button.secondary:before,
    .button.secondary:after {
      position: absolute;
      content: "";
      top: calc(100% - 2px);
      left: 0;
      width: 100%;
      height: 2px;
      background-color: currentColor;
      -webkit-transition-delay: 0s;
              transition-delay: 0s;
      -webkit-transition: -webkit-transform 0s;
      transition: -webkit-transform 0s;
      transition: transform 0s;
      transition: transform 0s, -webkit-transform 0s;
    }

input[type="submit"].secondary:before, button[type="submit"].secondary:before, button.secondary:before, .button.secondary:before {
      -webkit-transition: -webkit-transform 0.2s ease-in;
      transition: -webkit-transform 0.2s ease-in;
      transition: transform 0.2s ease-in;
      transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
    }

input[type="submit"].secondary:after, button[type="submit"].secondary:after, button.secondary:after, .button.secondary:after {
      -webkit-transform: translateX(-101%);
              transform: translateX(-101%);
      -webkit-transition: -webkit-transform 0.2s ease-out;
      transition: -webkit-transform 0.2s ease-out;
      transition: transform 0.2s ease-out;
      transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
      -webkit-transition-delay: 0.3s;
              transition-delay: 0.3s;
    }

@media (hover: hover) {
        input[type="submit"].secondary:hover:before, button[type="submit"].secondary:hover:before, button.secondary:hover:before, .button.secondary:hover:before {
          -webkit-transform: translateX(101%);
                  transform: translateX(101%);
        }

        input[type="submit"].secondary:hover:after, button[type="submit"].secondary:hover:after, button.secondary:hover:after, .button.secondary:hover:after {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
        }
    }

.button-group {
  display: -webkit-box;
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
}

.button-group .button:not(:last-child) {
      margin-right: 1rem;
    }

.button-group.adjusted {
  margin-top: -0.75rem;
}

.button-group.adjusted .button {
    margin-top: 0.75rem;
  }

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

legend {
  padding: 0;
}

label {
  font-size: 0.78947368rem;
  font-size: var(--font-size-meta);
  margin-bottom: 5px;
  display: block;
}

input {
  outline: none;
}

input:not([type="checkbox"]),
textarea,
select,
button {
  font-family: "Hill", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: var(--font-hill);
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

input:not([type="checkbox"])::-webkit-input-placeholder, textarea::-webkit-input-placeholder, select::-webkit-input-placeholder, button::-webkit-input-placeholder {
    color: currentColor;
    opacity: 0.75;
  }

input:not([type="checkbox"])::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder, button::-moz-placeholder {
    color: currentColor;
    opacity: 0.75;
  }

input:not([type="checkbox"]):-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder, button:-ms-input-placeholder {
    color: currentColor;
    opacity: 0.75;
  }

input:not([type="checkbox"])::-ms-input-placeholder, textarea::-ms-input-placeholder, select::-ms-input-placeholder, button::-ms-input-placeholder {
    color: currentColor;
    opacity: 0.75;
  }

input:not([type="checkbox"])::placeholder, textarea::placeholder, select::placeholder, button::placeholder {
    color: currentColor;
    opacity: 0.75;
  }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list]),
textarea {
  display: block;
  width: 100%;
  padding: 15px 0 10px 0;
  background: transparent;
  border-radius: 0;
  border: none;
  font-size: 1.3rem;
  font-size: var(--font-size-header-small);
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  line-height: 1.5em;
  line-height: var(--font-lineheight-inputs);
  color: currentColor;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list])::-moz-selection, textarea::-moz-selection {
    background: #f5f5f5;
    background: var(--color-white);
    color: #111111;
    color: var(--color-black);
  }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list])::selection, textarea::selection {
    background: #f5f5f5;
    background: var(--color-white);
    color: #111111;
    color: var(--color-black);
  }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list])::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: currentColor;
    opacity: 0.75;
  }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list])::-moz-placeholder, textarea::-moz-placeholder {
    color: currentColor;
    opacity: 0.75;
  }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list]):-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: currentColor;
    opacity: 0.75;
  }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list])::-ms-input-placeholder, textarea::-ms-input-placeholder {
    color: currentColor;
    opacity: 0.75;
  }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list])::placeholder, textarea::placeholder {
    color: currentColor;
    opacity: 0.75;
  }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list]):focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
      opacity: 0.5;
    }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list]):focus::-moz-placeholder, textarea:focus::-moz-placeholder {
      opacity: 0.5;
    }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list]):focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder {
      opacity: 0.5;
    }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list]):focus::-ms-input-placeholder, textarea:focus::-ms-input-placeholder {
      opacity: 0.5;
    }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list]):focus::placeholder, textarea:focus::placeholder {
      opacity: 0.5;
    }

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list]) + input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([list]) textarea, textarea + input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), textarea textarea {
    margin-top: -1rem;
  }

textarea {
  border: 2px solid currentColor;
  padding: 1rem;
  font-size: 1rem;
  font-size: var(--font-size-body);
  line-height: 1.33em;
  line-height: var(--font-lineheight-body);
  border-radius: 0;
  resize: vertical;
}

.checkbox {
  display: block;
  margin: 0;
  cursor: pointer;
  font-size: 0.78947368rem;
  font-size: var(--font-size-meta);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media (hover: hover) {
        .checkbox:hover input ~ .checkmark {
          opacity: 1;
        }
        .checkbox:hover input ~ .checklabel {
          opacity: 1;
        }
  }

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

.checkbox input:checked ~ .checklabel {
        opacity: 1;
      }

.checkbox input:checked ~ .checkmark {
        opacity: 1;
      }

.checkbox input:checked ~ .checkmark:after {
          display: block;
        }

.checkbox .checklabel {
    color: #9E9E9E;
    color: var(--color-minimum-contrast-to-black);
    display: -webkit-inline-box;
    display: inline-flex;
    font-size: 0.78947368rem;
    font-size: var(--font-size-meta);
    line-height: 1.33em;
    line-height: var(--font-lineheight-body);
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

.checkbox .checkmark {
    display: inline-block;
    position: relative;
    top: 2px;
    height: 16px;
    width: 16px;
    border: 2px solid currentColor;
    opacity: 0.5;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    margin-right: 1ch;
  }

.checkbox .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 3px;
      top: 1px;
      width: 6px;
      height: 9px;
      border: solid currentColor;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }
